export enum ChatType {
  none = "",
  twitter = "twitter",
  twitterDM = "twitter/dm",
  twitterReplay = "twitter/reply",
  twitterTweet = "twitter/tweet",
  twitterQuote = "twitter/quote",
  instagram = "instagram",
  instagramDM = "instagram/dm",
  instagramPost = "instagram/post",
  instagramReplay = "instagram/reply",
  instagramComment = "instagram/comment",
  whatsapp = "whatsapp",
  whatsappODA = "oda/whatsapp",
  whatsappVonage = "vonage/whatsapp",
  whatsappMessage = "message/whatsapp",
  whatsapp_ODA_HSM = "oda/whatsapp/hsm",
  email = "email",
  linkedin = "linkedIn",
  linkedinComment = "linkedin/comment",
  linkedinPost = "linkedin/post",
  directMsg = "directMsg",
  messanger = "messanger",
  facebookDM = "facebook/dm",
  facebookPost = "facebook/post",
  facebookComment = "facebook/comment",
  facebookReply = "facebook/reply",
  google = "google",
  youtube = "youtube",
  youtubeComment = "youtube/comment",
  googleBusiness = "google_business/location",
  m365Reply = "m365/reply",
  gmailReply = "gmail/reply",
  mailReply = "mail/reply",
  webchat = "webchat",
}

export enum ChatReactionType {
  like = "like",
  unlike = "unlike",
  retweet = "retweet",
  unretweet = "unretweet",
  delete = "delete",
}

export enum VideoCallSteamType {
  audio = "audio",
  video = "video",
}

export enum VideoCallDeviceType {
  videoinput = "videoinput",
  audioinput = "audioinput",
}
