import { findIndex, map } from "lodash";
import { IntialStateReports } from "./type";
import { GET_CONFIG_LIST, GET_MY_SESSIONS_ACTIVE, REVOKE_MY_SESSION_ACTIVE, UPDATE_CONFIG_LIST } from "./types";

const initialState: IntialStateReports = {
  configList: [],
  mySessionsActive: []
};

export default function securityReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  const { type, payload } = action;

  switch (type) {
    case GET_CONFIG_LIST: {
      return {
        ...state,
        configList: payload
      };
    }
    case UPDATE_CONFIG_LIST: {
      let configList = [...state?.configList]
      map(payload?.configs, (c) => {
        let targetIndex = findIndex(configList, (item) => item?.key === c?.key)
        configList[targetIndex] = {
          ...configList[targetIndex],
          value: c?.value
        }
      })
      console.log(configList,state?.configList)
      return {
        ...state,
        configList
      };
    }
    case GET_MY_SESSIONS_ACTIVE: {
      return {
        ...state,
        mySessionsActive: payload
      }
    }
    case REVOKE_MY_SESSION_ACTIVE: {

      let mySessionsActive: any = []
      state.mySessionsActive.forEach((item: any, index: number) => {
        if (!payload.session_id.some((element: any) => element === item?.id)) {
          mySessionsActive.push(item)
        }
      })
      return {
        ...state,
        mySessionsActive
      }
    }
    default:
      return state;
  }
}
